// components/Light.js

import React from 'react';

const Light = ({ width = '800px', height = '800px', fill = '#000000', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor" // Set to "currentColor" to allow changing color via classnames
      height="800px"
      width="800px"
      viewBox="0 0 32 32"
      className={className}
    >
      <g>
        <path d="M23.2,21.8c-0.5,0.5-1.3,0.5-1.8,0c-1.2-1.2-3.3-1.2-4.5,0c-0.5,0.5-1.3,0.5-1.8,0c-1.2-1.2-3.3-1.2-4.5,0
		c-0.5,0.5-1.3,0.5-1.8,0c-1.2-1.2-3.3-1.2-4.5,0c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0c0.5-0.5,1.3-0.5,1.8,0c1.2,1.2,3.3,1.2,4.5,0
		c0.5-0.5,1.3-0.5,1.8,0c1.2,1.2,3.3,1.2,4.5,0c0.5-0.5,1.3-0.5,1.8,0c0.6,0.6,1.4,0.9,2.3,0.9s1.6-0.3,2.3-0.9
		c0.5-0.5,1.3-0.5,1.8,0c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4C26.4,20.6,24.4,20.6,23.2,21.8z"/>
        <path d="M24.7,25.7c-0.7,0.5-1.8,0.5-2.5,0c-1.4-1.1-3.6-1.1-5,0c-0.7,0.5-1.8,0.5-2.5,0c-1.4-1.1-3.6-1.1-5,0
		c-0.7,0.5-1.8,0.5-2.5,0c-1.4-1.1-3.6-1.1-5,0c-0.4,0.3-0.5,1-0.1,1.4c0.3,0.4,1,0.5,1.4,0.1c0.7-0.5,1.8-0.5,2.5,0
		c0.7,0.6,1.6,0.8,2.5,0.8c0.9,0,1.8-0.3,2.5-0.8c0.7-0.5,1.8-0.5,2.5,0c1.4,1.1,3.6,1.1,5,0c0.7-0.5,1.8-0.5,2.5,0
		c1.4,1.1,3.6,1.1,5,0c0.7-0.5,1.8-0.5,2.5,0c0.4,0.3,1.1,0.3,1.4-0.1c0.3-0.4,0.3-1.1-0.1-1.4C28.2,24.6,26.1,24.6,24.7,25.7z"/>
        <path d="M16,7c0.6,0,1-0.4,1-1V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3C15,6.6,15.4,7,16,7z" />
        <path d="M8.2,9.6c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L7.5,6.1c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L8.2,9.6
		z"/>
        <path d="M3,17h3c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1,0.4-1,1S2.4,17,3,17z" />
        <path d="M25,16c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3C25.4,15,25,15.4,25,16z" />
        <path d="M23.1,9.9c0.3,0,0.5-0.1,0.7-0.3l2.1-2.1c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-2.1,2.1c-0.4,0.4-0.4,1,0,1.4
		C22.6,9.8,22.8,9.9,23.1,9.9z"/>
        <path d="M8.8,19.4C8.9,19.8,9.3,20,9.7,20h12.6c0.4,0,0.7-0.2,0.9-0.6c0.5-1.1,0.8-2.2,0.8-3.4c0-4.4-3.6-8-8-8s-8,3.6-8,8
		C8,17.2,8.3,18.3,8.8,19.4z"/>
      </g>
    </svg>
  );
};

export default Light;
